import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Menu from 'components/Menu'
import Input from 'components/Input'
import Button from 'components/Button'
import { palette } from 'styles/colors'
import { InputAdornment, IconButton } from '@material-ui/core'
import { columnsPromocodes } from 'data/columns'
import { useFormik } from 'formik'
import { Close } from '@material-ui/icons'
import TableWrapper from 'components/Table'
import NoContent from 'components/NoContent'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Delete from 'components/Modals/Delete'
import ButtonCreate from 'components/ButtonCreate'
import {
  getIsLoadingPromocoes,
  getPromocodesItems,
  getPromocodesPagination
} from 'redux/selectors/promocodes'
import {
  getPromocodes,
  editPromocode as editPromocodeAction,
  createPromocode as createPromocodeAction,
  deletePromocode as deletePromocodeAction
} from 'redux/modules/promocodes/actions'
import Promocode from 'components/Modals/Promocode'

const PromoCodes = ({ classes }) => {
  const dispatch = useDispatch()

  const promocodes = useSelector(getPromocodesItems)
  const isRequestsLoading = useSelector(getIsLoadingPromocoes)
  const promocodesPagination = useSelector(getPromocodesPagination)

  const formik = useFormik({
    initialValues: {
      search: ''
    },
    onSubmit: (values, { setFieldError }) => {
      fetchPromocodes(1)
    }
  })

  const fetchPromocodes = (page = promocodesPagination.page, newCount) => {
    if (newCount === 9999) newCount = promocodesPagination.perPage

    dispatch(
      getPromocodes(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || promocodesPagination.perPage,
          phrase: formik.values.search
        }
      )
    )
  }

  useEffect(() => {
    fetchPromocodes(promocodesPagination.page)
  }, [])

  useEffect(() => {
    document.title = 'Промокоды'
  }, [])

  const [editPromocode, setEditPromocode] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedPromocode) => {
    setEditPromocode(editedPromocode)
    setIsOpenedEdit(true)
  }
  const closeEditModerator = (editedModerator) => {
    setIsOpenedEdit(false)
  }

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedPromocode, setDeletedPromocode] = useState(null)

  const openDelete = (moderator) => {
    setDeletedPromocode(moderator)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setDeletedPromocode(null)
    setIsOpenedDelete(false)
  }

  const [isOpenedCreatePromocode, setIsOpenedCreatePromocode] = useState(false)

  const openCreatePromocode = () => {
    setIsOpenedCreatePromocode(true)
  }
  const closeCreatePromocode = () => {
    setIsOpenedCreatePromocode(false)
  }

  const [isLoading, setIsLoading] = useState(false)

  const createPromocode = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      createPromocodeAction(
        {
          code: values.code,
          title: values.title,
          isActive: true
        },
        (data) => {
          fetchPromocodes(1)
          setIsOpenedCreatePromocode(false)
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
          if (error.status === 403) {
            setFieldError('login', 'Такой код уже существует')
          }
        }
      )
    )
  }

  const editModerator = (values, { setFieldError }) => {
    dispatch(
      editPromocodeAction(
        {
          id: editPromocode.id,
          code: values.code,
          title: values.title,
          isActive: values.isActive
        },
        () => {
          fetchPromocodes(promocodesPagination.page)
          closeEditModerator()
        },
        () => setFieldError('login', 'Такой код уже существует')
      )
    )
  }

  const deletePromocode = () => {
    dispatch(
      deletePromocodeAction(
        { id: deletedPromocode.id },
        () => {
          fetchPromocodes(1)
          setIsOpenedDelete(false)
        },
        () => {}
      )
    )
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <Menu />
        <div className={classes.table}>
          <div className={classes.search}>
            <Input
              formik={formik}
              fieldName={'search'}
              label={'Найти промокод'}
              endAdornment={
                formik.values.search.length > 0 ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle"
                      style={{
                        color: palette.blue,
                        width: '40px',
                        height: '40px'
                      }}
                      onClick={() => {
                        formik.setFieldValue('search', '')
                        formik.handleSubmit()
                      }}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />
            <Button
              text={'Найти'}
              otherStyles={{
                width: '200px',
                height: '45px',
                backgroundColor: palette.blueButton,
                color: palette.white,
                marginRight: '2em',
                marginLeft: '2em'
              }}
              type="submit"
            />
          </div>
          {promocodes.length > 0 || isRequestsLoading ? (
            <>
              <div className={classes.tableWrapper}>
                <TableWrapper
                  page={promocodesPagination.page}
                  count={promocodesPagination.perPage}
                  amount={promocodesPagination.amount}
                  items={promocodes}
                  onChangePage={fetchPromocodes}
                  onChangePerPage={(newCount) => fetchPromocodes(1, newCount)}
                  isLoading={isRequestsLoading}
                  columns={columnsPromocodes}
                  tableType={'promocodes'}
                  actions={{
                    openEdit: openEdit,
                    openDelete: openDelete
                  }}
                />
              </div>
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreatePromocode}
                  text="Добавить промокод"
                />
              </div>
            </>
          ) : (
            <>
              <NoContent text={'Нет промокодов'} />
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreatePromocode}
                  text="Добавить промокод"
                />
              </div>
            </>
          )}
        </div>
        <ModalWrapper
          title={'Вы уверены?'}
          isOpened={isOpenedDelete}
          onClose={() => closeDelete()}
        >
          <Delete
            onDelete={() => deletePromocode()}
            onClose={() => closeDelete()}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Редактировать промокод'}
          isOpened={isOpenedEdit}
          onClose={() => closeEditModerator()}
          style={{ width: '600px' }}
        >
          <Promocode handleSubmit={editModerator} editedObj={editPromocode} />
        </ModalWrapper>
        <ModalWrapper
          title={'Создать промокод'}
          isOpened={isOpenedCreatePromocode}
          onClose={() => closeCreatePromocode()}
          style={{ width: '600px' }}
        >
          <Promocode handleSubmit={createPromocode} isLoading={isLoading} />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})

export default withStyles(styles)(PromoCodes)
