import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Menu from 'components/Menu'
import { InputAdornment, IconButton } from '@material-ui/core'
import Input from 'components/Input'
import { useFormik } from 'formik'
import Button from 'components/Button'
import { palette } from 'styles/colors'
import Checkbox from 'components/Checkbox'
import TableWrapper from 'components/Table'
import Delete from 'components/Modals/Delete'
import { columnsPatients } from 'data/columns'
import NoContent from 'components/NoContent'
import ModalWrapper from 'components/Modals/ModalWrapper'
import Patient from 'components/Modals/Patient'
import ButtonCreate from 'components/ButtonCreate'
import { Close } from '@material-ui/icons'
import {
  getPatientsItems,
  getPatientsPagination,
  getIsLoadingPatients,
  getLoadResultInfo
} from 'redux/selectors/patients'
import {
  createPatient as createPatientAction,
  editPatient as editPatientAction,
  deletePatient as deletePatientAction,
  getPatients,
  uploadUsersTable,
  getLoadResult
} from 'redux/modules/patients/actions'
import UploadXls from 'components/UploadXls'
import LastImportInfo from 'components/LastImportInfo'
import ImportStatus from 'components/ImportStatus'
import config from 'api/config'

const Patients = ({ classes }) => {
  const dispatch = useDispatch()

  const patients = useSelector(getPatientsItems)
  const isRequestsLoading = useSelector(getIsLoadingPatients)
  const patientsPagination = useSelector(getPatientsPagination)

  const formik = useFormik({
    initialValues: {
      search: '',
      bailikFinance: false,
      other: false
    },
    onSubmit: (values, { setFieldError }) => {
      fetchPatients(1)
    }
  })

  const fetchPatients = (
    page = patientsPagination.page,
    newCount = patientsPagination.perPage
  ) => {
    if (newCount === 9999) newCount = patientsPagination.perPage

    dispatch(
      getPatients(
        {},
        () => {},
        () => {},
        {
          page,
          count: newCount || patientsPagination.perPage,
          phrase: formik.values.search,
          source:
            formik.values.bailikFinance && formik.values.other
              ? ''
              : formik.values.bailikFinance
              ? 'bilyk_finance'
              : formik.values.other
              ? 'current'
              : ''
        }
      )
    )
  }

  useEffect(() => {
    fetchPatients(patientsPagination.page)
    dispatch(getLoadResult())

    const timer = setInterval(() => {
      dispatch(getLoadResult())
    }, 10000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    document.title = 'Пациенты'
  }, [])

  const [patient, setPatient] = useState('')
  const [isOpenedEdit, setIsOpenedEdit] = useState(false)

  const openEdit = (editedPatient) => {
    setPatient(editedPatient)
    setIsOpenedEdit(true)
  }
  const closeEdit = (editedPatient) => {
    // setSpecialist(editedRecording)
    setIsOpenedEdit(false)
  }

  const [isOpenedDelete, setIsOpenedDelete] = useState(false)
  const [deletedPatient, setDeletedPatient] = useState(null)

  const openDelete = (patient) => {
    setDeletedPatient(patient)
    setIsOpenedDelete(true)
  }

  const closeDelete = () => {
    setDeletedPatient(null)
    setIsOpenedDelete(false)
  }

  const [isOpenedCreatePatient, setIsOpenedCreatePatient] = useState(false)

  const openCreatePatient = () => {
    setIsOpenedCreatePatient(true)
  }
  const closeCreatePatient = () => {
    setIsOpenedCreatePatient(false)
  }

  const [isLoading, setIsLoading] = useState(false)

  const createPatient = (values, { setFieldError }) => {
    setIsLoading(true)
    dispatch(
      createPatientAction(
        {
          phone: values.phone
            .replace(/\s/g, '')
            .replace(/\+/g, '')
            .replace(/-/g, ''),
          password: values.password,
          passport: values.passport,
          fullName: values.fullName,
          inn: values.inn,
          sex: values.sex,
          birthDate: values.birthDate
        },
        (data) => {
          fetchPatients(1)
          setIsOpenedCreatePatient(false)
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
          if (error.status === 403) {
            setFieldError(error.description, 'Такое значение уже существует')
          }
        }
      )
    )
  }

  const editPatient = (values, { setFieldError }) => {
    let patientCreate = {
      id: patient.id,
      phone: values.phone
        .replace(/\s/g, '')
        .replace(/\+/g, '')
        .replace(/-/g, ''),
      password: values.password,
      fullName: values.fullName,
      inn: values.inn,
      sex: values.sex,
      status: values.status
    }
    if (values.passport) {
      patientCreate = {
        ...patientCreate,
        passport: values.passport
      }
    } else {
      patientCreate = {
        ...patientCreate,
        passport: null
      }
    }
    if (values.birthDate) {
      patientCreate = {
        ...patientCreate,
        birthDate: values.birthDate
      }
    } else {
      patientCreate = {
        ...patientCreate,
        birthDate: null
      }
    }

    dispatch(
      editPatientAction(
        patientCreate,
        () => {
          fetchPatients(patientsPagination.page)
          closeEdit()
        },
        () => setFieldError('phone', 'Такой телефон уже существует')
      )
    )
  }

  const deletePatient = () => {
    dispatch(
      deletePatientAction(
        { id: deletedPatient.id },
        () => {
          fetchPatients(1)
          setIsOpenedDelete(false)
        },
        () => {}
      )
    )
  }

  const [isLoadingFile, setIsLoadingFile] = useState(false)

  const handleChange = (event) => {
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    setIsLoadingFile(true)

    dispatch(
      uploadUsersTable(
        { file: formData },
        () => {
          fetchPatients(patientsPagination.page)
          setIsLoadingFile(false)
        },
        () => {
          setIsLoadingFile(false)
        }
      )
    )
  }

  const [isShowDrawer, setIsShowDrawer] = useState(false)

  const info = useSelector(getLoadResultInfo)

  // const info = {
  //   status: 'failed'
  // }

  const downloadPatients = async () => {
    const token = await JSON.parse(localStorage.JWT_KEY)
    let a = document.createElement('a')
    a.href = `${config.axios.baseURL}/users/download_users_events?auth_token=${token.accessToken}`
    a.download = `patients.xlsx`
    a.target = '_blank'
    a.click()
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.wrapper}>
        <Menu />
        <div className={classes.table}>
          <div className={classes.search}>
            <div className={classes.filters}>
              <UploadXls
                exportText={'Загрузить таблицу из БФ'}
                handleChange={handleChange}
                isLoading={isLoadingFile}
                disabled={info ? info.status === 'in_progress' : false}
              />
              <Button
                text={'Экспорт сторонних пациентов в XLS'}
                otherStyles={{
                  height: '45px',
                  backgroundColor: palette.blueButton,
                  color: palette.white,
                  marginRight: '2em',
                  marginTop: '0'
                }}
                onClick={downloadPatients}
              />
              <Checkbox
                label={'Байлык Финанс'}
                formik={formik}
                fieldName={'bailikFinance'}
                type="submit"
                onKeyPress={(event) => {
                  return event.preventDefault()
                }}
              />
              <Checkbox
                label={'Сторонних'}
                formik={formik}
                fieldName={'other'}
                type="submit"
                onKeyPress={(event) => {
                  return event.preventDefault()
                }}
              />
            </div>
            <div className={classes.input}>
              <Input
                formik={formik}
                fieldName={'search'}
                label={'Найти пользователя'}
                endAdornment={
                  formik.values.search.length > 0 ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle"
                        style={{
                          color: palette.blue,
                          width: '40px',
                          height: '40px'
                        }}
                        onClick={() => {
                          formik.setFieldValue('search', '')
                          formik.handleSubmit()
                        }}
                      >
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  )
                }
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    return event.preventDefault()
                  }
                }}
              />
              <Button
                text={'Найти'}
                otherStyles={{
                  width: '200px',
                  height: '45px',
                  backgroundColor: palette.blueButton,
                  color: palette.white,
                  marginRight: '2em',
                  marginLeft: '2em',
                  marginTop: '0'
                }}
                type="submit"
              />
            </div>
          </div>
          {info && (
            <div className={classes.lastImport}>
              <p>
                Статус последнего импорта: <ImportStatus status={info.status} />
              </p>
              {info.status !== 'in_progress' && (
                <Button
                  text={'Подробнее'}
                  otherStyles={{
                    width: '270px',
                    height: '45px',
                    backgroundColor: palette.blueButton,
                    color: palette.white,
                    marginRight: '2em',
                    marginLeft: '2em',
                    marginTop: 0
                  }}
                  onClick={() => setIsShowDrawer(true)}
                />
              )}
              <LastImportInfo
                isShowDrawer={isShowDrawer}
                setIsShowDrawer={setIsShowDrawer}
              />
            </div>
          )}
          {patients.length > 0 || isRequestsLoading ? (
            <>
              <div className={classes.tableWrapper}>
                <TableWrapper
                  page={patientsPagination.page}
                  count={patientsPagination.perPage}
                  amount={patientsPagination.amount}
                  items={patients}
                  onChangePage={fetchPatients}
                  onChangePerPage={(newCount) => fetchPatients(1, newCount)}
                  isLoading={isRequestsLoading}
                  columns={columnsPatients}
                  tableType={'patients'}
                  actions={{
                    openDelete: openDelete,
                    openEdit: openEdit
                  }}
                />
              </div>
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreatePatient}
                  text="Добавить пациента"
                />
              </div>
            </>
          ) : (
            <>
              <NoContent text={'Нет пациентов'} />
              <div className={classes.button}>
                <ButtonCreate
                  openCreate={openCreatePatient}
                  text="Добавить пациента"
                />
              </div>
            </>
          )}
        </div>
        <ModalWrapper
          title={'Вы уверены?'}
          isOpened={isOpenedDelete}
          onClose={() => closeDelete()}
        >
          <Delete
            onDelete={() => deletePatient()}
            onClose={() => closeDelete()}
          />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка пользователя'}
          isOpened={isOpenedEdit}
          onClose={() => closeEdit()}
          style={{ width: '800px' }}
        >
          <Patient handleSubmit={editPatient} editedObj={patient} />
        </ModalWrapper>
        <ModalWrapper
          title={'Карточка пользователя'}
          isOpened={isOpenedCreatePatient}
          onClose={() => closeCreatePatient()}
          style={{ width: '800px' }}
        >
          <Patient handleSubmit={createPatient} isLoading={isLoading} />
        </ModalWrapper>
      </div>
    </form>
  )
}

const styles = () => ({
  wrapper: {
    display: 'flex'
  },
  table: {
    width: '100%',
    padding: '1em 0'
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: '2em'
  },
  input: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  filter: {
    marginRight: '40px'
  },
  tableWrapper: {
    padding: '0 2em'
  },
  button: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  lastImport: {
    marginLeft: '2em',
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    color: 'green'
  }
})

export default withStyles(styles)(Patients)
