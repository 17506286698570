import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from 'components/Button'
import { useDispatch } from 'react-redux'
import { editReceptionFields } from 'redux/modules/receptions/actions'

const DecisionChange = ({ classes, onClose, reception }) => {
  const dispatch = useDispatch()

  const setDecision = (decision) => {
    dispatch(
      editReceptionFields(
        { specialistDecision: decision, receptionId: reception.id },
        (data) => {
          onClose(true)
        },
        (error) => {
          console.log('error', error)
        }
      )
    )
  }

  return (
    <div className={classes.root}>
      <Button
        otherStyles={{ margin: '0 1em 1em 0' }}
        fullWidth
        active
        text="Подтвержден"
        onClick={() => setDecision('confirmed')}
      />
      <Button
        otherStyles={{ margin: '0 0 1em 1em' }}
        fullWidth
        active
        text="Время изменено"
        onClick={() => setDecision('time_changed')}
      />
      <Button
        otherStyles={{ margin: '0 0 1em 1em' }}
        fullWidth
        text="Отклонен"
        onClick={() => setDecision('rejected')}
      />
    </div>
  )
}

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '550px'
  }
})

export default withStyles(styles)(DecisionChange)
