import { createReducer } from '@reduxjs/toolkit'
import {
  SET_SPECIALISTS,
  SET_SPECIALIST,
  SET_SPECIALITIES,
  SET_SHEDULES,
  SET_SCHEDULE_RECEPTIONS,
  SET_REGIONS,
  SET_ALL_SPECIALISTS
} from './actions'
import { SUCCESS, REQUEST, FAILURE } from '../../constants/statuses'

const initialState = {
  specialists: [],
  allSpecialists: [],
  specialist: null,
  specialities: [],
  shedules: [],
  scheduleReceptions: [],
  scheduleId: 0,
  pagination: {
    page: 1,
    perPage: 10,
    amount: 2
  },
  isLoading: false,
  isError: false,
  regions: []
}

export const specialistsReducer = createReducer(initialState, {
  [SET_SPECIALISTS[SUCCESS]]: (state, action) => {
    state.specialists = action.payload.data.specialists
    state.pagination.amount = action.payload.data.amount
    state.pagination.page = action.payload.paging.page
    console.log('action.payload.paging.count', action.payload.paging)

    if (action.payload.paging.count !== 9999) {
      state.pagination.perPage = action.payload.paging.count
    }
    state.isLoading = false
  },

  [SET_SPECIALISTS[REQUEST]]: (state, action) => {
    state.isError = false
    state.isLoading = true
  },

  [SET_SPECIALISTS[FAILURE]]: (state, action) => {
    state.isLoading = false
    state.isError = true
  },

  [SET_SPECIALITIES[SUCCESS]]: (state, action) => {
    state.specialities = action.payload.data
  },

  [SET_SHEDULES[SUCCESS]]: (state, action) => {
    state.shedules = action.payload.data.scheduleData.dates
    state.scheduleId = action.payload.data.id
  },

  [SET_SCHEDULE_RECEPTIONS[SUCCESS]]: (state, action) => {
    state.scheduleReceptions = action.payload.data
  },

  [SET_SPECIALIST[SUCCESS]]: (state, action) => {
    state.specialist = action.payload.data
  },

  [SET_REGIONS[SUCCESS]]: (state, action) => {
    state.regions = action.payload.data
  },

  [SET_ALL_SPECIALISTS[SUCCESS]]: (state, action) => {
    state.allSpecialists = action.payload.data.specialists
  }
})
